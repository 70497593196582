import React from "react"
import Layout from "../components/Layout"
import Seo from '../components/SEO'
import '../styles/global.css'
import LogoType from '../../assets/logotype.svg'
import YouTubeIcon from '../components/icons/YouTubeIcon'
import EnvelopeIcon from '../components/icons/EnvelopeIcon'
import TwitterIcon from '../components/icons/TwitterIcon'

export default function Home() {
  return (
    <Layout>
      <Seo title="Home" />
      <div className="flex flex-1 w-96 items-center justify-items-center mx-auto">
        <div className="flex flex-col p-4 w-full">

          <LogoType />

          {/* <div className="flex flex-col justify-items-center items-center my-12 font-bold font-open-sans italic text-gray-700 text-sm text-center">
            "Test"
          </div> */}

          <div className="flex flex-row justify-items-center items-center mt-12">
            <a className="font-open-sans font-bold text-lg text-center items-center justify-items-center" href="https://www.youtube.com/channel/UC6RcZ7hYXLkfQuWGXQ2xntg" target="_blank" rel="noreferrer">
              <YouTubeIcon className="text-brands-everything-text hover:text-brands-youtube w-8 transition-all hover:pb-10" />
            </a>
            <a className="font-open-sans font-bold text-lg text-center items-center ml-4" href="https://twitter.com/everythingproj" target="_blank" rel="noreferrer" title="@everythingproj">
              <TwitterIcon className="text-brands-everything-text hover:text-brands-twitter w-7 transition-all" />
            </a>
            <a className="font-open-sans font-bold text-lg text-center items-center justify-items-center ml-4" href="mailto:contact@everythingproject.net" title="contact@everythingproject.net">
              <EnvelopeIcon className="text-brands-everything-text hover:text-gray-900 w-8 transition-all" />
            </a>
          </div>
          
        </div>
      </div>
    </Layout>
  )
}