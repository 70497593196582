import React from 'react'
import Footer from './Footer'

export default function Layout({ children }) {
    return (
        <div className="flex flex-col min-h-screen bg-gradient-to-r from-gray-100 to-gray-200 bg-gradient-to-br">
            {/* <Navbar /> */}
            { children }
            <Footer />
        </div>
    )
}
